import { FlowConfig } from '../base';

export const FlowSendPublicReport: FlowConfig = (() => {
  const LOG_SEND_EMAIL = "SEND_EMAIL"
  const LOG_NO_SITES = "NO_SITES"

  return {
    config: {
      id: "send_public_report",
      name: "Send Report",
      description: "Send a link of a report via email. If you do not select any sites, the flow will not run.",
      triggers: [{ type: "schedule", value: { durationMinutes: 30 } }],
      parameters: [
        {
          id: "to_emails",
          name: "To Emails",
          description: "List of emails to send the report to",
          required: true,
          typing: {
            type: "array"
          },
        },
      ],
      members: ['sites'],
      deviceScreens: [],
      canBeTriggeredByOtherFlows: false
    },
    device: {
      tick: async () => { },
    },
    server: {
      tick: async (context, flowInstance) => {
        const now = new Date();
        const logs = await flowInstance.getLogs();
        const parameters = flowInstance.getParameters();
        const toEmails = parameters.to_emails;
        const siteMembers = await flowInstance.getSiteMembers();

        async function noSitesLinkedLog() {
          const lastLogNoSites = logs.filter((log) => { log.tags.includes(LOG_NO_SITES) }).pop()
          if (!lastLogNoSites || now > new Date(lastLogNoSites.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "No sites linked to this flow. You need to link at least one site to this flow." }, [LOG_NO_SITES])
          }
        }

        // If no sites are linked, skip tick
        if (siteMembers.length === 0) { await noSitesLinkedLog(); return; }

        // Get environment variables
        const URL_SERVICE_BACKEND = context.environment.URL_SERVICE_BACKEND;

        if (toEmails.length > 0) {
          const lastLogEmail = logs.filter(log => log.tags.includes(LOG_SEND_EMAIL)).pop()
          // If log exists, skip
          if (lastLogEmail) return

          // Send email
          await context.notification.sendEmails([
            {
              type: 'REPORT',
              target: toEmails.join(";"),
              buttons: [
                {
                  text: "View Report",
                  url: `${URL_SERVICE_BACKEND}/public/report?site_uuids=${siteMembers.map(site => site.uuid).join("%2C")}`
                }
              ]
            }
          ])

          // Create log
          await flowInstance.createLog({ msg: "Send email report", uuidSites: siteMembers.map(site => site.uuid), toEmails }, [LOG_SEND_EMAIL])
        }
      }
    }
  }
})()
