import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkOrganizationDevices } from "./bean_link_organization_device";
import { deviceStateLogs } from "./bean_device_state_log";
import { deviceActivityLogs } from "./bean_device_activity_log";
import { linkDeviceUserLoggedInStates } from "./bean_link_device_user_logged_in_state";
import { deviceStockStatusLogs } from "./bean_device_stock_status_log";
import { flowInstanceDeviceTermination } from "./bean_flow_instance_device_termination";

/**
 * Represents a physical device in the system (Android phone)
 * @property serial - The serial number of the device
 * @property imei0 - The primary IMEI number of the device
 * @property imei1 - The secondary IMEI number of the device (optional)
 */
export const devices = sqliteTable('devices', {
  ...schemaBase(),
  serial: text('serial').notNull(),
  imei0: text('imei0').notNull(),
  imei1: text('imei1'),
});

export const devicesRelations = relations(devices, ({ many }) => ({
  devicesToUserLoggedInStates: many(linkDeviceUserLoggedInStates),
  devicesToOrganizations: many(linkOrganizationDevices),
  devicesToFlowInstanceDeviceTermination: many(flowInstanceDeviceTermination),
  activityLogs: many(deviceActivityLogs),
  stateLogs: many(deviceStateLogs),
  stockStatusLogs: many(deviceStockStatusLogs),
}));