import { useEffect, useState } from 'react';
import { FlowParameter, FlowParameterTypeInteger } from 'vigil-flow';
import { InputText } from '../../../../components/input_text';

type FlowParameterInteger = FlowParameter & { typing: FlowParameterTypeInteger };

interface FlowParameterIntegerInputProps {
  parameter: FlowParameterInteger;
  value: number;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: number) => void;
}

export const FlowParameterIntegerInput: React.FC<FlowParameterIntegerInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const numberValidation = parameter.typing;

  const validateInteger = (value: number): void => {
    const newErrors: string[] = [];
    
    if (numberValidation) {
      if (typeof numberValidation.min === 'number' && value < numberValidation.min) {
        newErrors.push(`Minimum value is ${numberValidation.min}`);
      }
      if (typeof numberValidation.max === 'number' && value > numberValidation.max) {
        newErrors.push(`Maximum value is ${numberValidation.max}`);
      }
      if (numberValidation.step && value % numberValidation.step !== 0) {
        newErrors.push(`Must be in steps of ${numberValidation.step}`);
      }
    }
    
    setErrors(newErrors);
    onValidationErrors?.(newErrors);
  };

  useEffect(() => {
    validateInteger(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      validateInteger(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputText
        className="w-44"
        inputType="numberInt"
        labelText=""
        value={value !== undefined ? value : ""}
        onChange={handleChange}
        errors={errors}
        step={numberValidation?.step}
        min={numberValidation?.min}
        max={numberValidation?.max}
      />
    </div>
  );
}; 