import { useContext, useEffect, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { InputSelect } from "../../../components/input_select";
import { IFlowSelect, IFlowTrigger } from "vigil-datamodel";
import { FlowConfig, VIGIL_FLOWS, FlowParameterTypeString, FlowParameterTypeInteger, FlowParameterTypeFloat, FlowParameterTypeBoolean, FlowParamaterTypeCron, FlowParamaterTypeDuration, FlowParameterTypeArray, User, Site, Device, Beacon, CRON, FlowTrigger } from "vigil-flow";
import { InputButton } from "../../../components/input_button";
import { useStateCallback } from "../../../hooks/use_state_callback";
import { FullPageLoader } from "../../../components/full_page_loader";
import { FlowParameterStringInput, FlowParameterCronInput, FlowParameterIntegerInput, FlowParameterFloatInput, FlowParameterBooleanInput, FlowParameterArrayInput, FlowParameterDurationInput, FlowParameterOptionInput } from "./parameters";
import { BeaconsMembers, DevicesMembers, SitesMembers, UsersMembers } from "./members";
import { ScheduleTrigger } from "./triggers";

interface ModalFlowCreateProps extends ModalProps {
  onSubmit?: (flow: IFlowSelect) => Promise<void>;
}

export const ModalFlowCreate: React.FC<ModalFlowCreateProps> = (props) => {
  const allFlows = VIGIL_FLOWS;
  const flowOptions = allFlows.map((flow) => ({ value: flow.config.id, label: flow.config.name }));

  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  // Form state
  const [name, setName] = useState<string>('');
  const [selectedFlow, setSelectedFlow] = useState<FlowConfig>(allFlows[0]);

  // Parameters state
  const [parameterValues, setParameterValues] = useState<Record<string, any>>({});
  const [parameterErrors, setParameterErrors] = useState<Record<string, string[]>>({});

  // Triggers state
  const [triggers, setTriggers] = useState<IFlowTrigger[]>([]);
  const [triggerErrors, setTriggerErrors] = useState<string[]>([]);

  // Members state
  const [selectedMembers, setSelectedMembers] = useState<Record<string, string[]>>({});
  const [memberErrors, setMemberErrors] = useState<Record<string, string[]>>({});

  // Available members state
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [availableSites, setAvailableSites] = useState<Site[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [availableBeacons, setAvailableBeacons] = useState<Beacon[]>([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);

  const getTimezoneOffsetHours = (): number => (new Date().getTimezoneOffset() / 60);

  /* Fetch Members */
  useEffect(() => {
    const fetchMembers = async () => {
      if (!organization.data) return;

      setIsLoadingMembers(true);
      try {
        // Fetch all member types in parallel
        const [users, sites, devices, beacons] = await Promise.all([
          vigil.functions.findManyUsers({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManySites({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyDevices({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyBeacons({ uuidOrganization: organization.data.uuid })
        ]);

        setAvailableUsers(users.map(u => ({ ...u, idNumber: u.idNumber || '', email: u.email || '', mobile: u.mobile || '' })));
        setAvailableSites(sites);
        setAvailableDevices(devices.map(d => ({ ...d, imei1: d.imei1 || '' })));
        setAvailableBeacons(beacons);
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setIsLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [organization.data]);

  /* Initialize Flow Parameters */
  useEffect(() => {
    // Reset states when flow changes

    // Name
    setName('');

    // Parameters
    const newParameterValues: Record<string, any> = {};
    for (const parameter of selectedFlow.config.parameters) {
      if (parameter.typing.type === 'string') newParameterValues[parameter.id] = '';
      else if (parameter.typing.type === 'integer') newParameterValues[parameter.id] = 0;
      else if (parameter.typing.type === 'float') newParameterValues[parameter.id] = 0;
      else if (parameter.typing.type === 'boolean') newParameterValues[parameter.id] = false;
      else if (parameter.typing.type === 'array') newParameterValues[parameter.id] = [];
      else if (parameter.typing.type === 'duration') newParameterValues[parameter.id] = { hours: 0, minutes: 0 };
      else if (parameter.typing.type === 'cron') newParameterValues[parameter.id] = `0 ${12 + getTimezoneOffsetHours()} * * *`;
      else if (parameter.typing.type === 'option') newParameterValues[parameter.id] = parameter.typing.options[0].value;
      else throw new Error(`Unknown parameter type: ${parameter.typing}`);
    }
    setParameterValues(newParameterValues);
    setParameterErrors({});

    // Triggers
    const newTriggers: IFlowTrigger[] = [];
    for (const trigger of selectedFlow.config.triggers) {
      if (trigger.type === 'schedule') {
        newTriggers.push({
          type: trigger.type,
          value: {
            cron: trigger.value?.cron ?? `0 ${12 + getTimezoneOffsetHours()} * * *` as CRON,
            durationMinutes: trigger.value?.durationMinutes ?? 0
          }
        });
      }
    }
    setTriggers(newTriggers);
    setTriggerErrors([]);

    // Members
    setSelectedMembers({});
    setMemberErrors({});
  }, [selectedFlow]);

  /* Validation */
  const validateName = () => {
    if (!name) return [];
    return validate(name, [VALIDATORS.length('Flow name', 2, 30)]);
  };

  const validateForm = () => {
    const errors: string[] = [];

    // Name validation
    if (!name) errors.push('Flow name is required');
    if (validateName().length > 0) errors.push('Invalid flow name');

    // Parameters validation
    if (Object.values(parameterErrors).some(errs => errs.length > 0)) {
      errors.push(...Object.values(parameterErrors).flat());
    }

    // Required parameters validation
    selectedFlow.config.parameters.forEach(param => {
      if (param.required && !parameterValues[param.id]) {
        errors.push(`Parameter ${param.name} is required`);
      }
    });

    // Triggers validation
    if (triggerErrors.length > 0) {
      errors.push(...triggerErrors);
    }

    // Members validation
    if (Object.values(memberErrors).some(errs => errs.length > 0)) {
      errors.push('Invalid member selection');
    }

    return errors;
  };

  /* Event Handlers */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleFlowChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const flow = allFlows.find(f => f.config.id === event.target.value);
    if (flow) {
      setSelectedFlow(flow);
    }
  };

  const handleParameterChange = (parameterId: string, value: any) => {
    setParameterValues(prev => ({
      ...prev,
      [parameterId]: value
    }));
  };

  const handleParameterValidationErrors = (parameterId: string, errors: string[]) => {
    setParameterErrors(prev => ({
      ...prev,
      [parameterId]: errors
    }));
  };

  const handleTriggerChange = (value: { cron?: string; durationMinutes?: number }) => {
    if (value.cron && typeof value.durationMinutes === 'number') {
      setTriggers([{
        type: "schedule",
        value: {
          cron: value.cron as CRON,
          durationMinutes: value.durationMinutes
        }
      }]);
    }
  };

  const handleTriggerValidationErrors = (errors: string[]) => {
    setTriggerErrors(errors);
  };

  const handleMemberChange = (memberType: string, uuids: string[]) => {
    setSelectedMembers(prev => ({
      ...prev,
      [memberType]: uuids
    }));
  };

  const handleMemberValidationErrors = (memberType: string, errors: string[]) => {
    setMemberErrors(prev => ({
      ...prev,
      [memberType]: errors
    }));
  };

  /* Submit Handler */
  const createFlow = useStateCallback(async () => {
    if (!organization.data) return;

    const allMembers = Object.values(selectedMembers).flat();

    console.log(name, selectedFlow.config.id, triggers, parameterValues, allMembers);

    const flow = await vigil.functions.createFlow({
      data: {
        uuidOrganization: organization.data.uuid,
        name: name,
        id: selectedFlow.config.id,
        triggers: triggers as IFlowTrigger[],
        parameterValues: parameterValues,
        uuidMembers: allMembers,
      }
    });

    props.onSubmit && await props.onSubmit(flow);
    props.toggle();

    // Reset form
    setSelectedFlow(allFlows[0]);
  });

  if (!organization.data || isLoadingMembers) return <FullPageLoader />;

  const formErrors = validateForm();

  return (
    <Modal className='w-192' isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Flow</h3>

      {/* Basic Info Card */}
      <div className="card bg-base-200 shadow-sm mb-2 p-4">
        <div className="flex flex-wrap gap-4">
          <InputText
            className='w-60'
            labelText='Name'
            value={name}
            onChange={handleNameChange}
            errors={validateName()}
          />
          <InputSelect
            className='w-full'
            labelText='Flow Type'
            value={selectedFlow.config.id}
            onChange={handleFlowChange}
            options={flowOptions}
            errors={[]}
          />
        </div>
      </div>

      <div className="overflow-y-auto space-y-2">
        {/* Description Card */}
        <div className="card bg-base-200 shadow-sm p-4">
          <h4 className="font-bold text-m mb-2">Description</h4>
          <div className="text-sm">{selectedFlow.config.description}</div>
        </div>

        {/* Triggers Card */}
        <div className="card bg-base-200 shadow-sm p-4">
          <h4 className="font-bold text-m mb-2">Trigger</h4>
          {triggers.map((trigger, index) => {
            switch (trigger.type) {
              case "schedule":
                const showCron = selectedFlow.config.triggers[index].value && selectedFlow.config.triggers[index].value.cron ? false : true;
                const showDuration = selectedFlow.config.triggers[index].value && selectedFlow.config.triggers[index].value.durationMinutes ? false : true;

                return (
                  <ScheduleTrigger
                    key={trigger.type + index}
                    value={trigger.value as any}
                    onChange={handleTriggerChange}
                    onValidationErrors={handleTriggerValidationErrors}
                    showCron={showCron}
                    showDuration={showDuration}
                  />
                )
              default:
                return null;
            }
          })}
        </div>

        {/* Parameters Card */}
        {selectedFlow.config.parameters.length > 0 && (
          <div className="card bg-base-200 shadow-sm p-4">
            <h4 className="font-bold text-m mb-2">Parameters</h4>
            <div className="space-y-4">
              {selectedFlow.config.parameters.map((parameterConfig) => {
                const parameterType = parameterConfig.typing.type;
                const parameterId = parameterConfig.id;
                const value = parameterValues[parameterId];

                switch (parameterType) {
                  case 'string':
                    return (
                      <FlowParameterStringInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeString }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'integer':
                    return (
                      <FlowParameterIntegerInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeInteger }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'float':
                    return (
                      <FlowParameterFloatInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeFloat }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'boolean':
                    return (
                      <FlowParameterBooleanInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeBoolean }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'array':
                    return (
                      <FlowParameterArrayInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeArray }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'duration':
                    return (
                      <FlowParameterDurationInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParamaterTypeDuration }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'cron':
                    return (
                      <FlowParameterCronInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParamaterTypeCron }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  case 'option':
                    return (
                      <FlowParameterOptionInput
                        key={parameterId}
                        parameter={{ ...parameterConfig, typing: parameterConfig.typing as any }}
                        value={value}
                        onChange={(v) => handleParameterChange(parameterId, v)}
                        onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        )}

        {/* Members Card */}
        {selectedFlow.config.members.length > 0 && (
          <div className="card bg-base-200 shadow-sm p-4">
            <h4 className="font-bold text-m mb-2">Members</h4>
            <div className="space-y-4">
              {selectedFlow.config.members.includes('users') && (
                <UsersMembers
                  availableUsers={availableUsers}
                  selectedUsers={(selectedMembers['users']?.map(uuid => availableUsers.find(u => u.uuid === uuid)).filter(Boolean) || []) as User[]}
                  onChange={(users) => handleMemberChange('users', users.map(u => u.uuid))}
                />
              )}
              {selectedFlow.config.members.includes('sites') && (
                <SitesMembers
                  availableSites={availableSites}
                  selectedSites={(selectedMembers['sites']?.map(uuid => availableSites.find(s => s.uuid === uuid)).filter(Boolean) || []) as Site[]}
                  onChange={(sites) => handleMemberChange('sites', sites.map(s => s.uuid))}
                />
              )}
              {selectedFlow.config.members.includes('devices') && (
                <DevicesMembers
                  availableDevices={availableDevices}
                  selectedDevices={(selectedMembers['devices']?.map(uuid => availableDevices.find(d => d.uuid === uuid)).filter(Boolean) || []) as Device[]}
                  onChange={(devices) => handleMemberChange('devices', devices.map(d => d.uuid))}
                />
              )}
              {selectedFlow.config.members.includes('beacons') && (
                <BeaconsMembers
                  availableBeacons={availableBeacons}
                  selectedBeacons={(selectedMembers['beacons']?.map(uuid => availableBeacons.find(b => b.uuid === uuid)).filter(Boolean) || []) as Beacon[]}
                  onChange={(beacons) => handleMemberChange('beacons', beacons.map(b => b.uuid))}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {/* Error Display */}
      {createFlow.error && (
        <StatusAlert message={createFlow.error} type="alert-error" />
      )}

      {/* Submit Button */}
      <div className="flex justify-end pt-4">
        <InputButton
          text='Create Flow'
          loading={createFlow.loading}
          disabled={formErrors.length > 0}
          type='btn-primary'
          onClick={async () => await createFlow.call()}
        />
      </div>
    </Modal>
  );
};