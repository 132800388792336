import { FC, useContext } from 'react';
import { IFlowInstanceSelect, IDirectoryFlowInstanceLog } from 'vigil-datamodel';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useTableData } from '../../hooks/use_table_data';
import { Table } from '../../components/table/table';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { TTuuid } from 'tt-uuid';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';

interface ScreenHomeFlowInstanceLogsProps { }

export const ScreenHomeFlowInstanceLogs: FC<ScreenHomeFlowInstanceLogsProps> = (props) => {
  const { flowInstanceParent, fetchFlowInstanceParent } = useOutletContext<{
    flowInstanceParent: IFlowInstanceSelect,
    fetchFlowInstanceParent: () => void
  }>();

  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { data: flowInstanceLogs, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryFlowInstanceLog>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryFlowInstanceLogs({
        uuidOrganization: organization.data.uuid,
        filters: [...filters, { id: 'uuidFlowInstance', query: 'equals', value: flowInstanceParent.uuid }],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  // TODO: Add later, so that we can see more details about the logs
  // function navigateFlowInstance(selectedFlowInstance: IDirectoryFlowInstanceLog) {
  //   navigate(ROUTES.ROUTE_HOME_FLOW_INSTANCE.replace(':uuid', flowParent.uuid).replace(':instance_uuid', selectedFlowInstance.uuid))
  // }

  return (
    <div className='flex flex-col h-full'>
      <Table<IDirectoryFlowInstanceLog>
        className='h-full flex flex-col drop-shadow my-2'
        name='Flow Instances'
        data={flowInstanceLogs}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'data',
            header: 'Data',
            sortable: false,
            value(item) {
              return <div>{JSON.stringify(item.data)}</div>;
            },
          },
          {
            id: 'tags',
            header: 'Tags',
            sortable: false,
            value(item) {
              return <div>{item.tags.join(', ')}</div>;
            },
          },
          {
            id: 'creator',
            header: 'Creator',
            sortable: false,
            value(item) {
              if (item.creator.type === 'server') return <div>Server</div>;
              return <div>Device UUID: {item.creator.uuidDevice}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        filtersOptions={[
          new TextFilter('data', 'Data', 'contains'),
          new TextFilter('tags', 'Tags', 'contains'),
          new TextFilter('creator.uuidDevice', 'Creator Device UUID', 'contains'),
          new TextFilter('creator.type', 'Creator Type', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
}; 