import { useEffect, useState } from 'react';
import { FlowParameter, FlowParameterTypeArray } from 'vigil-flow';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';

type FlowParameterArray = FlowParameter & { typing: FlowParameterTypeArray };

interface FlowParameterArrayInputProps {
  parameter: FlowParameterArray;
  value: Array<any>;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: Array<any>) => void;
}

export const FlowParameterArrayInput: React.FC<FlowParameterArrayInputProps> = ({
  parameter,
  value = [],
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const arrayValidation = parameter.typing;
  const [options] = useState<TagOption[]>([]); // For now, empty options until we implement options fetching

  const validateArray = (items: any[]): void => {
    const newErrors: string[] = [];

    if (parameter.required && items.length < 1) {
      newErrors.push('At least 1 item is required');
    }

    if (arrayValidation) {
      if (typeof arrayValidation.minItems === 'number' && items.length < arrayValidation.minItems) {
        newErrors.push(`Minimum ${arrayValidation.minItems} items required`);
      }
      if (typeof arrayValidation.maxItems === 'number' && items.length > arrayValidation.maxItems) {
        newErrors.push(`Maximum ${arrayValidation.maxItems} items allowed`);
      }
    }

    setErrors(newErrors);
    onValidationErrors?.(newErrors);
  };

  const selectedTags = (value || []).map(v => ({
    label: v?.toString() || '',
    value: v
  }));

  const handleChange = (tags: TagOption[]): void => {
    const values = tags.map(t => t.value);
    validateArray(values);
    onChange?.(values);
  };

  useEffect(() => {
    validateArray(value || []);
  }, [value]);

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputTagSelect
        options={options}
        selectedTags={selectedTags}
        onChange={handleChange}
        allowCustomTags={true}
        errors={errors}
        labelText=""
      />
    </div>
  );
}; 