import { FC, useContext } from 'react';
import { IDirectoryFlowInstance, IFlowSelect } from 'vigil-datamodel';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useTableData } from '../../hooks/use_table_data';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ROUTES } from '../../router/routes';
import { NumberFilter } from '../../components/table/table_filters/table_filter_number';

interface ScreenHomeFlowInstancesProps { }

export const ScreenHomeFlowInstances: FC<ScreenHomeFlowInstancesProps> = (props) => {
  const { flowParent, fetchFlowParent } = useOutletContext<{
    flowParent: IFlowSelect,
    fetchFlowParent: () => void
  }>();
  
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { data: flowInstances, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryFlowInstance>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryFlowInstances({
        uuidOrganization: organization.data.uuid,
        filters: [...filters, { id: 'uuidFlow', query: 'equals', value: flowParent.uuid }],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateFlowInstance(selectedFlowInstance: IDirectoryFlowInstance) {
    navigate(ROUTES.ROUTE_HOME_FLOW_INSTANCE.replace(':uuid', flowParent.uuid).replace(':instance_uuid', selectedFlowInstance.uuid))
  }

  return (
    <div className='flex flex-col h-full'>
      <Table<IDirectoryFlowInstance>
        className='h-full flex flex-col drop-shadow my-2'
        name='Flow Instances'
        data={flowInstances}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          // TODO: Fix sortability of nested fields
          {
            id: 'dateTimeStart',
            header: 'Date Start',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateFlowInstance(item)}>
                  {new Date(item.dateTimeStart).toLocaleDateString() + " " + new Date(item.dateTimeStart).toLocaleTimeString()}
                </div>
              );
            },
          },
          {
            id: 'dateTimeEnd',
            header: 'Date End',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateFlowInstance(item)}>
                  {new Date(item.dateTimeEnd).toLocaleDateString() + " " + new Date(item.dateTimeEnd).toLocaleTimeString()}
                </div>
              );
            },
          },
          {
            id: 'flowSnapshot.version',
            header: 'Flow Version',
            sortable: false,
            value(item) {
              return <div>{item.flowSnapshot.version}.0</div>;
            },
          },
          {
            id: 'flowSnapshot.parametersLabel',
            header: 'Flow Parameters',
            sortable: false,
            value(item) {
              return (
                <div>
                  {item.flowSnapshot.parametersLabel.split('; ').map((param, index) => {
                    const [key, value] = param.split(': ');
                    return (
                      <span key={index}>
                        <span className="font-semibold">{key}</span>: <span className="italic">{value}</span>
                        {index < item.flowSnapshot.parametersLabel.split('; ').length - 1 ? '; ' : ''}
                      </span>
                    );
                  })}
                </div>
              );
            },
          },
        ]}

        filtersOptions={[
          new DateFilter('dateTimeStart', 'Date Started', 'between'),
          new DateFilter('dateTimeEnd', 'Date Ended', 'between'),
          new NumberFilter('flowSnapshot.version', 'Flow Version', 'between'),
          // TODO: Need to fix this filter, it does not work
          new TextFilter('flowSnapshot.parametersLabel', 'Parameters', 'contains'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
}; 