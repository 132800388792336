import { FlowConfig } from "../base";

export const FlowDeviceAdoptSiteSpecificFlows: FlowConfig = (() => {
  const TAG_NO_DEVICES_LINKED = "TAG_NO_DEVICES_LINKED"
  const TAG_ADOPTED_FLOW = "TAG_ADOPTED_FLOW"

  return {
    config: {
      id: "device_adopt_site_specific_flows",
      name: "Device Adopt Site Specific Flows",
      description: "Flow that runs on a device to adopt site specific flows. Note: If you make 'All Devices' true, it will override the 'Devices' parameter. If you do not select any devices, the flow will not run.",
      triggers: [{ type: "schedule" }],
      parameters: [
        {
          id: "radius_from_beacons_meters",
          name: "Radius From Beacons (meters)",
          description: "The radius from the beacons to adopt the flows",
          required: true,
          typing: {
            type: "integer",
            min: 10,
            max: 500,
            step: 10
          },
        },
      ],
      members: ['devices'],
      deviceScreens: [],
      canBeTriggeredByOtherFlows: false,
    },
    device: {
      tick: async (context, flowInstance) => {
        const now = new Date();
        const logs = await flowInstance.getLogs();
        const parameters = flowInstance.getParameters();

        // Extract required context objects
        const { organization, device, vigil } = context;

        const radiusFromBeaconsMeters = parameters.radius_from_beacons_meters;

        // Exit if device has no location
        const deviceLocation = await device.getLocation();
        if (!deviceLocation) return;

        // Exit if organization has no beacons
        const beacons = await organization.getBeacons();
        if (beacons.length === 0) return;

        // Get beacons within configured radius, sorted by distance
        const sortedBeacons = beacons
          .filter(beacon => vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
          .sort((a, b) => vigil.distance(a, deviceLocation) - vigil.distance(b, deviceLocation));
        if (sortedBeacons.length === 0) return;

        // Process beacons in order of proximity
        for (const beacon of sortedBeacons) {
          // Skip beacons with no linked sites
          const sites = await organization.getSitesLinkedToBeacon(beacon.uuid);
          if (sites.length === 0) continue;

          // Process sites linked to current beacon
          for (const site of sites) {
            const flows = await organization.getFlowsLinkedToSite(site.uuid);
            for (const flow of flows) {
              if (!flow.canBeAdopted) continue;
              const flowInstances = await device.adoptFlow(flow, now)

              for (const flowInstance of flowInstances) {
                const logsExist = logs.filter(log => log.tags.includes(TAG_ADOPTED_FLOW) && log.data.uuidFlowInstance === flowInstance.uuid).pop()
                if (logsExist) continue;
                await flowInstance.createLog({ msg: "Adopted flow", uuidFlowInstance: flowInstance.uuid }, [TAG_ADOPTED_FLOW]);
              }
            }
          }
        }
      },
    },
    server: {
      tick: async (context, flowInstance) => {
        const now = new Date();
        const logs = await flowInstance.getLogs();
        const parameters = flowInstance.getParameters();
        const radiusFromBeaconsMeters = parameters.radius_from_beacons_meters;

        async function noDevicesLinkedLog() {
          const lastLogNoDevices = logs.filter((log) => { log.tags.includes(TAG_NO_DEVICES_LINKED) }).pop()
          if (!lastLogNoDevices || now > new Date(lastLogNoDevices.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "No devices linked to this flow. You need to link at least one device to this flow." }, [TAG_NO_DEVICES_LINKED])
          }
        }

        // If 10 minutes has not yet passed, skip
        if (now < new Date(flowInstance.dateTimeStart.valueOf() + 10 * 60 * 1000)) return;

        // Get all devices
        const deviceMembers = await flowInstance.getDeviceMembers()

        // If no devices are linked, skip
        if (deviceMembers.length === 0) { await noDevicesLinkedLog(); return; }

        for (const deviceMember of deviceMembers) {
          // Get device SDK
          const deviceSDK = await context.organization.getSDKDevice(deviceMember.uuid)
          if (!deviceSDK) { console.error("Device SDK not found"); continue; }

          // Skip if device has no location
          const deviceLocation = await deviceSDK.getLocation();
          if (!deviceLocation) continue;

          // Skip if organization has no beacons
          const beacons = await context.organization.getBeacons();
          if (beacons.length === 0) continue;

          // Get beacons within configured radius, sorted by distance, skip if no beacons are found
          const sortedBeacons = beacons
            .filter(beacon => context.vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
            .sort((a, b) => context.vigil.distance(a, deviceLocation) - context.vigil.distance(b, deviceLocation));
          if (sortedBeacons.length === 0) continue;

          // Process beacons in order of proximity
          for (const beacon of sortedBeacons) {
            // Skip beacons with no linked sites
            const sites = await context.organization.getSitesLinkedToBeacon(beacon.uuid);
            if (sites.length === 0) continue;

            // Process sites linked to current beacon
            for (const site of sites) {
              const flows = await context.organization.getFlowsLinkedToSite(site.uuid);
              for (const flow of flows) {
                if (!flow.canBeAdopted) continue;
                const flowInstances = await deviceSDK.adoptFlow(flow, now)

                for (const flowInstance of flowInstances) {
                  const logsExist = logs.filter(log => log.tags.includes(TAG_ADOPTED_FLOW) && log.data.uuidFlowInstance === flowInstance.uuid).pop()
                  if (logsExist) continue;
                  await flowInstance.createLog({ msg: "Adopted flow", uuidFlowInstance: flowInstance.uuid }, [TAG_ADOPTED_FLOW]);
                }
              }
            }
          }
        }
      },
    }
  }
})()
