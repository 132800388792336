import { FC, useContext, useState } from 'react';
import { useModal } from '../../hooks/use_modal';
import { IDirectoryFlow, Permissions } from 'vigil-datamodel';
import { useNavigate } from 'react-router-dom';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextRoles } from '../../providers/provider_roles';
import { useTableData } from '../../hooks/use_table_data';
import { TTActions } from 'tt-permissions';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ROUTES } from '../../router/routes';
import { ModalDeleteMany } from './modal_delete_many';
import { ModalFlowCreate } from './func_flow/modal_flow_create';

interface ScreenHomeFlowsProps { }

export const ScreenHomeFlows: FC<ScreenHomeFlowsProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedFlowsState, setSelectedFlows] = useState<IDirectoryFlow[]>([]);

  const { data: flows, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryFlow>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryFlows({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function deleteFlows(selectedFlows: IDirectoryFlow[]) {
    setSelectedFlows(selectedFlows);
    toggleModalDelete();
  }

  function navigateFlow(selectedFlow: IDirectoryFlow) {
    navigate(ROUTES.ROUTE_HOME_FLOW.replace(':uuid', selectedFlow.uuid))
  }

  async function _deleteFlows(flowUuids: string[]) {
    if (!organization.data) return;
    await vigil.functions.deleteFlows({
      uuidOrganization: organization.data.uuid,
      uuids: flowUuids
    })
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalFlowCreate
        isOpen={isOpenModalCreate}
        toggle={toggleModalCreate}
        onSubmit={forceRefetch}
      />
      <ModalDeleteMany
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='flows'
        data={selectedFlowsState.map(flow => ({ uuid: flow.uuid, label: flow.name }))}
        onSubmit={forceRefetch}
        deleteCallback={async () => _deleteFlows(selectedFlowsState.map(flow => flow.uuid))}
        extraComponents={
          <div>
            <ul className="list-disc ml-6">
              <li><strong>Terminate ALL active flow instances related to this flow</strong></li>
              <li>If a device that is running this flow is offline, it will only be terminated when it comes back online</li>
            </ul>
          </div>
        }
      />

      <Table<IDirectoryFlow>
        className='h-full flex flex-col drop-shadow'
        name='Flows'
        data={flows}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateFlow(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'id',
            header: 'Flow ID',
            sortable: true,
            value(item) {
              return <div>{item.id}</div>;
            },
          },
          {
            id: 'parameters',
            header: 'Flow Parameters',
            sortable: false,
            value(item) {
              return (
                <div>
                  {item.parametersLabel.split('; ').map((param, index) => {
                    const [key, value] = param.split(': ');
                    return (
                      <span key={index}>
                        <span className="font-semibold">{key}</span>: <span className="italic">{value}</span>
                        {index < item.parametersLabel.split('; ').length - 1 ? '; ' : ''}
                      </span>
                    );
                  })}
                </div>
              );
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />Add new Flow</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RFlow),
            onClick: () => toggleModalCreate(),
          }
        ]}

        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new TextFilter('id', 'Flow ID', 'contains'),
          new TextFilter('parametersLabel', 'Parameters', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RFlow),
            onClick: (selectedItems) => deleteFlows(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RFlow),
            onClick: (selectedItems) => deleteFlows(selectedItems)
          }
        ]}
      />
    </div>
  );
}; 