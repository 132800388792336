import { useContext } from 'react';
import { IDirectoryFlowInstance } from 'vigil-datamodel';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useTableData } from '../../hooks/use_table_data';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ROUTES } from '../../router/routes';
import { NumberFilter } from '../../components/table/table_filters/table_filter_number';
import { useNavigate } from 'react-router-dom';

interface ScreenHomeOverviewActiveInstancesProps { }

export const ScreenHomeOverviewActiveInstances: React.FC<ScreenHomeOverviewActiveInstancesProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { data: flowInstances, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryFlowInstance>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryFlowInstances({
        uuidOrganization: organization.data.uuid,
        filters: [...filters, { id: 'dateTimeStart', query: 'lessThan', value: new Date() }, { id: 'dateTimeEnd', query: 'greaterThan', value: new Date() }],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateFlowInstance(selectedFlowInstance: IDirectoryFlowInstance) {
    navigate(ROUTES.ROUTE_HOME_FLOW_INSTANCE.replace(':uuid', selectedFlowInstance.uuidFlow).replace(':instance_uuid', selectedFlowInstance.uuid))
  }

  return (
    <div className='flex flex-col h-full'>
      <Table<IDirectoryFlowInstance>
        className='h-full flex flex-col drop-shadow'
        name='Flow Instances'
        data={flowInstances}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          // TODO: Fix sortability of nested fields
          {
            id: 'flowSnapshot.name',
            header: 'Flow Name',
            sortable: false,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateFlowInstance(item)}>
                  {item.flowSnapshot.name}
                </div>
              )
            },
          },
          {
            id: 'flowSnapshot.id',
            header: 'Flow ID',
            sortable: false,
            value(item) {
              return <div>{item.flowSnapshot.id}</div>;
            },
          },
          {
            id: 'flowSnapshot.version',
            header: 'Flow Version',
            sortable: false,
            value(item) {
              return <div>{item.flowSnapshot.version}.0</div>;
            },
          },
          {
            id: 'dateTimeStart',
            header: 'Date Start',
            sortable: true,
            value(item) {
              return (
                <div>
                  {new Date(item.dateTimeStart).toLocaleDateString() + " " + new Date(item.dateTimeStart).toLocaleTimeString()}
                </div>
              );
            },
          },
          {
            id: 'dateTimeEnd',
            header: 'Date End',
            sortable: true,
            value(item) {
              return (
                <div>
                  {new Date(item.dateTimeEnd).toLocaleDateString() + " " + new Date(item.dateTimeEnd).toLocaleTimeString()}
                </div>
              );
            },
          },
          // {
          //   id: 'flowSnapshot.parametersLabel',
          //   header: 'Flow Parameters',
          //   sortable: false,
          //   value(item) {
          //     return (
          //       <div>
          //         {item.flowSnapshot.parametersLabel.split('; ').map((param, index) => {
          //           const [key, value] = param.split(': ');
          //           return (
          //             <span key={index}>
          //               <span className="font-semibold">{key}</span>: <span className="italic">{value}</span>
          //               {index < item.flowSnapshot.parametersLabel.split('; ').length - 1 ? '; ' : ''}
          //             </span>
          //           );
          //         })}
          //       </div>
          //     );
          //   },
          // },
        ]}

        filtersOptions={[
          new TextFilter('flowSnapshot.name', 'Flow Name', 'contains'),
          new TextFilter('flowSnapshot.id', 'Flow ID', 'contains'),
          new NumberFilter('flowSnapshot.version', 'Flow Version', 'between'),
          new DateFilter('dateTimeStart', 'Date Started', 'between'),
          new DateFilter('dateTimeEnd', 'Date Ended', 'between'),
          // TODO: Need to fix this filter, it does not work
          // new TextFilter('flowSnapshot.parametersLabel', 'Parameters', 'contains'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
};
