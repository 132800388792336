import { FlowParameter, FlowParameterTypeBoolean } from 'vigil-flow';
import { Checkbox } from '../../../../components/checkbox';
import { InputBase } from '../../../../components/input_base';

type FlowParameterBoolean = FlowParameter & { typing: FlowParameterTypeBoolean };

interface FlowParameterBooleanInputProps {
  parameter: FlowParameterBoolean;
  value: boolean;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: boolean) => void;
}

export const FlowParameterBooleanInput: React.FC<FlowParameterBooleanInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm mb-1">{parameter.description}</div>
      <InputBase labelText="" errors={[]}>
        <Checkbox
          label={parameter.name}
          checked={value}
          toggle={() => onChange?.(!value)}
        />
      </InputBase>
    </div>
  );
}; 