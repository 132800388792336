import { User } from 'vigil-flow';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';

interface UsersMembersProps {
  availableUsers: User[];
  selectedUsers: User[];
  onChange?: (users: User[]) => void;
}

export const UsersMembers: React.FC<UsersMembersProps> = ({
  availableUsers,
  selectedUsers,
  onChange,
}) => {
  // Convert users to tag options
  const userOptions: TagOption[] = availableUsers.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.uuid,
  }));

  const selectedTags: TagOption[] = selectedUsers.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.uuid,
  }));

  const handleChange = (tags: TagOption[]): void => {
    // Convert selected tags back to User objects
    const users = tags.map(tag => {
      const user = availableUsers.find(u => u.uuid === tag.value);
      return user!;
    });
    onChange?.(users);
  };

  return (
    <div>
      <div className="font-bold text-sm">Users</div>
      <div className="italic text-sm">Select the users that should be linked to this flow (order matters)</div>
      <InputTagSelect
        options={userOptions}
        selectedTags={selectedTags}
        onChange={handleChange}
        errors={[]}
        labelText=""
      />
    </div>
  );
};
