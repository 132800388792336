import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { flows, IFlow } from "./bean_flow";
import { flowInstanceLogs } from "./bean_flow_instance_log";
import { flowInstanceDeviceTermination } from "./bean_flow_instance_device_termination";

export const flowInstances = sqliteTable('flow_instances', {
  ...schemaBase(),
  dateTimeStart: integer('date_time_start', { mode: 'timestamp' }).notNull(),
  dateTimeEnd: integer('date_time_end', { mode: 'timestamp' }).notNull(),
  dateTimeServerTerminated: integer('date_time_server_terminated', { mode: 'timestamp' }),
  uuidFlow: text('uuid_flow').notNull().references(() => flows.uuid),
  flowSnapshot: customJsonColumn<IFlow>('flow').notNull(),
});

export const flowInstancesRelations = relations(flowInstances, ({ one, many }) => ({
  flowInstanceLogs: many(flowInstanceLogs),
  flow: one(flows, {
    fields: [flowInstances.uuidFlow],
    references: [flows.uuid],
  }),
  flowInstanceToDeviceTermination: many(flowInstanceDeviceTermination),
}));
