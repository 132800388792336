import React, { useContext, useEffect, useState } from 'react';
import { useModal } from '../../hooks/use_modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { ROUTES } from '../../router/routes';
import { IFlowSelect, IFlowTrigger } from 'vigil-datamodel';
import { InputText } from '../../components/input_text';
import { VALIDATORS, validate } from '../../validation';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { ModalDeleteMany } from './modal_delete_many';
import { ModalUpdateOne } from './modal_update_one';
import { CRON, VIGIL_FLOWS, Beacon, Device, Site, User, FlowParamaterTypeCron, FlowParamaterTypeDuration, FlowParameterTypeArray, FlowParameterTypeBoolean, FlowParameterTypeFloat, FlowParameterTypeInteger, FlowParameterTypeString } from 'vigil-flow';
import { FullPageLoader } from '../../components/full_page_loader';
import { FlowParameterStringInput, FlowParameterCronInput, FlowParameterIntegerInput, FlowParameterFloatInput, FlowParameterBooleanInput, FlowParameterArrayInput, FlowParameterDurationInput, FlowParameterOptionInput } from './func_flow/parameters';
import { UsersMembers, SitesMembers, DevicesMembers, BeaconsMembers } from './func_flow/members';
import { ScheduleTrigger } from './func_flow/triggers';
import { FullPageError } from '../../components/full_page_error';

interface ScreenHomeFlowOverviewProps { }

export const ScreenHomeFlowOverview: React.FC<ScreenHomeFlowOverviewProps> = (props) => {
  const { flowParent, fetchFlowParent } = useOutletContext<{
    flowParent: IFlowSelect,
    fetchFlowParent: () => void
  }>();
  const flow = VIGIL_FLOWS.find(f => f.config.id === flowParent.id);

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const navigate = useNavigate();

  // Form state
  const [name, setName] = useState<string>(flowParent.name);

  // Parameters state
  const [parameterValues, setParameterValues] = useState<Record<string, any>>(flowParent.parameterValues);
  const [parameterErrors, setParameterErrors] = useState<Record<string, string[]>>({});

  // Triggers state
  const [triggers, setTriggers] = useState<IFlowTrigger[]>(flowParent.triggers);
  const [triggerErrors, setTriggerErrors] = useState<string[]>([]);

  // Members state
  const [selectedMembers, setSelectedMembers] = useState<Record<string, string[]>>({});
  const [memberErrors, setMemberErrors] = useState<Record<string, string[]>>({});

  // Available members state
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [availableSites, setAvailableSites] = useState<Site[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [availableBeacons, setAvailableBeacons] = useState<Beacon[]>([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);

  const { isOpen: isOpenModalDeleteFlow, toggle: toggleModalDeleteFlow } = useModal();
  const { isOpen: isOpenModalUpdateFlow, toggle: toggleModalUpdateFlow } = useModal();

  /* Fetch Members */
  useEffect(() => {
    const fetchMembers = async () => {
      if (!organization.data) return;

      setIsLoadingMembers(true);
      try {
        // Fetch all member types in parallel
        const [users, sites, devices, beacons] = await Promise.all([
          vigil.functions.findManyUsers({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManySites({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyDevices({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyBeacons({ uuidOrganization: organization.data.uuid })
        ]);

        setSelectedMembers({
          users: flowParent.uuidMembers.filter(uuid => users.some(u => u.uuid === uuid)),
          sites: flowParent.uuidMembers.filter(uuid => sites.some(s => s.uuid === uuid)),
          devices: flowParent.uuidMembers.filter(uuid => devices.some(d => d.uuid === uuid)),
          beacons: flowParent.uuidMembers.filter(uuid => beacons.some(b => b.uuid === uuid))
        });
        setAvailableUsers(users.map(u => ({ ...u, idNumber: u.idNumber || '', email: u.email || '', mobile: u.mobile || '' })));
        setAvailableSites(sites);
        setAvailableDevices(devices.map(d => ({ ...d, imei1: d.imei1 || '' })));
        setAvailableBeacons(beacons);
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setIsLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [organization.data]);

  /* Validation */
  const validateName = () => {
    if (!name) return [];
    return validate(name, [VALIDATORS.length('Flow name', 2, 30)]);
  };

  const validateForm = () => {
    const errors: string[] = [];

    // Name validation
    if (!name) errors.push('Flow name is required');
    if (validateName().length > 0) errors.push('Invalid flow name');

    // Parameters validation
    if (Object.values(parameterErrors).some(errs => errs.length > 0)) {
      errors.push(...Object.values(parameterErrors).flat());
    }

    // Required parameters validation
    flow?.config.parameters.forEach(param => {
      if (param.required && !parameterValues[param.id]) {
        errors.push(`Parameter ${param.name} is required`);
      }
    });

    // Triggers validation
    if (triggerErrors.length > 0) {
      errors.push(...triggerErrors);
    }

    // Members validation
    if (Object.values(memberErrors).some(errs => errs.length > 0)) {
      errors.push('Invalid member selection');
    }

    // Check if there is a difference between the new flow values and the old flow values
    const hasChanges =
      flowParent.name !== name ||
      JSON.stringify(flowParent.triggers) !== JSON.stringify(triggers) ||
      JSON.stringify(flowParent.parameterValues) !== JSON.stringify(parameterValues) ||
      JSON.stringify(flowParent.uuidMembers) !== JSON.stringify(Object.values(selectedMembers).flat());

    if (!hasChanges) {
      errors.push('No changes detected');
    }

    return errors;
  };

  /* Event Handlers */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleParameterChange = (parameterId: string, value: any) => {
    setParameterValues(prev => ({
      ...prev,
      [parameterId]: value
    }));
  };

  const handleParameterValidationErrors = (parameterId: string, errors: string[]) => {
    setParameterErrors(prev => ({
      ...prev,
      [parameterId]: errors
    }));
  };

  const handleTriggerChange = (value: { cron?: string; durationMinutes?: number }) => {
    if (value.cron && typeof value.durationMinutes === 'number') {
      setTriggers([{
        type: "schedule",
        value: {
          cron: value.cron as CRON,
          durationMinutes: value.durationMinutes
        }
      }]);
    }
  };

  const handleTriggerValidationErrors = (errors: string[]) => {
    setTriggerErrors(errors);
  };

  const handleMemberChange = (memberType: string, uuids: string[]) => {
    setSelectedMembers(prev => ({
      ...prev,
      [memberType]: uuids
    }));
  };

  const handleMemberValidationErrors = (memberType: string, errors: string[]) => {
    setMemberErrors(prev => ({
      ...prev,
      [memberType]: errors
    }));
  };

  async function _deleteFlow() {
    if (!organization.data) return;
    await vigil.functions.deleteFlows({
      uuidOrganization: organization.data.uuid,
      uuids: [flowParent.uuid]
    })
  }

  async function _updateFlow() {
    if (!organization.data) return;
    await vigil.functions.updateFlow({
      uuidOrganization: organization.data.uuid,
      uuid: flowParent.uuid,
      data: {
        ...flowParent,
        name: name,
        parameterValues: parameterValues,
        triggers: triggers,
        uuidMembers: Object.values(selectedMembers).flat()
      }
    });
  }

  if (!organization.data || isLoadingMembers) return <FullPageLoader />;
  if (!flow) return <FullPageError error='Flow not found' />;

  const formErrors = validateForm();

  return (
    <div className='h-full w-full flex flex-col'>
      <ModalDeleteMany
        isOpen={isOpenModalDeleteFlow}
        toggle={toggleModalDeleteFlow}
        type='flows'
        data={[{ uuid: flowParent.uuid, label: flowParent.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_FLOWS)}
        deleteCallback={_deleteFlow}
        extraComponents={
          <div>
            <ul className="list-disc ml-6">
              <li><strong>Terminate ALL active flow instances related to this flow</strong></li>
              <li>If a device that is running this flow is offline, it will only be terminated when it comes back online</li>
            </ul>
          </div>
        }
      />
      <ModalUpdateOne
        isOpen={isOpenModalUpdateFlow}
        toggle={toggleModalUpdateFlow}
        updateCallback={_updateFlow}
        onSubmit={async () => { fetchFlowParent(); }}
        body={
          <div>
            <div className="mt-2">This will:</div>
            <ul className="list-disc ml-6">
              <li><strong>Terminate ALL active flow instances related to this flow</strong></li>
              <li>If a device that is running this flow is offline, it will only be terminated when it comes back online</li>
              <li>Update the flow instance with the new parameters</li>
              <li>Create a new flow instance with the new parameters</li>
            </ul>
          </div>
        }
      />

      <div className='relative h-full mt-2'>
        <div className='absolute w-full h-full overflow-y-scroll'>

          <div className='rounded-t-xl bg-base-300 p-2'>
            <div className='flex items-center'>
              <div className='font-semibold flex flex-grow'>Overview</div>
              <InputButton
                text='Save'
                before={<IconPencilSquareSolid className='h-5 mr-1' />}
                type='btn-primary'
                size='btn-sm'
                disabled={formErrors.length > 0}
                onClick={toggleModalUpdateFlow}
              />
              <div className='w-2'></div>
              <InputButton
                text='Delete'
                before={<IconTrashSolid className='h-5 mr-1' />}
                type='btn-error'
                size='btn-sm'
                loading={false}
                onClick={toggleModalDeleteFlow}
              />
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
            <div className="card bg-base-300 shadow-sm mb-2 p-4">
              <div className="flex flex-wrap gap-4">
                <InputText
                  className="w-60 mb-2 mr-4"
                  labelText='Flow name'
                  value={name}
                  onChange={handleNameChange}
                  errors={validateName()}
                />
                <div className="w-60 mb-2 mr-4">
                  <label className="label">
                    <span className="label-text">Flow Type</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    value={flow?.config.name || ''}
                    disabled
                  />
                </div>
                <div className="w-60 mb-2 mr-4">
                  <label className="label">
                    <span className="label-text">Flow Version: {flowParent.version}.0</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="overflow-y-auto space-y-2">
              {/* Description Card */}
              <div className="card bg-base-300 shadow-sm p-4">
                <h4 className="font-bold text-m mb-2">Description</h4>
                <div className="text-sm">{flow.config.description}</div>
              </div>

              {/* Triggers Card */}
              <div className="card bg-base-300 shadow-sm p-4">
                <h4 className="font-bold text-m mb-2">Trigger</h4>
                {triggers.map((trigger, index) => {
                  switch (trigger.type) {
                    case "schedule":
                      const showCron = flow.config.triggers[index].value && flow.config.triggers[index].value.cron ? false : true;
                      const showDuration = flow.config.triggers[index].value && flow.config.triggers[index].value.durationMinutes ? false : true;

                      return (
                        <ScheduleTrigger
                          key={trigger.type + index}
                          value={trigger.value as any}
                          onChange={handleTriggerChange}
                          onValidationErrors={handleTriggerValidationErrors}
                          showCron={showCron}
                          showDuration={showDuration}
                        />
                      )
                    default:
                      return null;
                  }
                })}
              </div>

              {/* Parameters Card */}
              {flow.config.parameters.length > 0 && (
                <div className="card bg-base-300 shadow-sm p-4">
                  <h4 className="font-bold text-m mb-2">Parameters</h4>
                  <div className="space-y-4">
                    {Object.keys(parameterValues).map((parameterKey, index) => {
                      const parameterConfig = flow.config.parameters[index];
                      const parameterType = parameterConfig.typing.type;
                      const parameterId = parameterConfig.id;

                      const value = parameterValues[parameterKey];

                      switch (parameterType) {
                        case 'string':
                          return (
                            <FlowParameterStringInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeString }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'integer':
                          return (
                            <FlowParameterIntegerInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeInteger }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'float':
                          return (
                            <FlowParameterFloatInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeFloat }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'boolean':
                          return (
                            <FlowParameterBooleanInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeBoolean }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'array':
                          return (
                            <FlowParameterArrayInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParameterTypeArray }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'duration':
                          return (
                            <FlowParameterDurationInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParamaterTypeDuration }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'cron':
                          return (
                            <FlowParameterCronInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as FlowParamaterTypeCron }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'option':
                          return (
                            <FlowParameterOptionInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as any }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
              )}

              {/* Members Card */}
              {flow.config.members.length > 0 && (
                <div className="card bg-base-300 shadow-sm p-4">
                  <h4 className="font-bold text-m mb-2">Members</h4>
                  <div className="space-y-4">
                    {flow.config.members.includes('users') && (
                      <UsersMembers
                        availableUsers={availableUsers}
                        selectedUsers={(selectedMembers['users']?.map(uuid => availableUsers.find(u => u.uuid === uuid)).filter(Boolean) || []) as User[]}
                        onChange={(users) => handleMemberChange('users', users.map(u => u.uuid))}
                      />
                    )}
                    {flow.config.members.includes('sites') && (
                      <SitesMembers
                        availableSites={availableSites}
                        selectedSites={(selectedMembers['sites']?.map(uuid => availableSites.find(s => s.uuid === uuid)).filter(Boolean) || []) as Site[]}
                        onChange={(sites) => handleMemberChange('sites', sites.map(s => s.uuid))}
                      />
                    )}
                    {flow.config.members.includes('devices') && (
                      <DevicesMembers
                        availableDevices={availableDevices}
                        selectedDevices={(selectedMembers['devices']?.map(uuid => availableDevices.find(d => d.uuid === uuid)).filter(Boolean) || []) as Device[]}
                        onChange={(devices) => handleMemberChange('devices', devices.map(d => d.uuid))}
                      />
                    )}
                    {flow.config.members.includes('beacons') && (
                      <BeaconsMembers
                        availableBeacons={availableBeacons}
                        selectedBeacons={(selectedMembers['beacons']?.map(uuid => availableBeacons.find(b => b.uuid === uuid)).filter(Boolean) || []) as Beacon[]}
                        onChange={(beacons) => handleMemberChange('beacons', beacons.map(b => b.uuid))}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
