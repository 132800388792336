import { useState, useEffect } from 'react';
import { CRON } from 'vigil-flow';
import { InputCron } from '../../../../components/input_cron';
import { InputDuration, DurationState } from '../../../../components/input_duration';

interface ScheduleTriggerProps {
  value: {
    cron: CRON;
    durationMinutes: number;
  };
  onChange?: (value: { cron?: CRON; durationMinutes?: number }) => void;
  onValidationErrors?: (errors: string[]) => void;
  showCron?: boolean;
  showDuration?: boolean;
}

export const ScheduleTrigger: React.FC<ScheduleTriggerProps> = ({
  value,
  onChange,
  onValidationErrors,
  showCron = true,
  showDuration = true,
}) => {
  const [errors, setErrors] = useState<{ cron: string[]; duration: string[] }>({ cron: [], duration: [] });
  const [localValue, setLocalValue] = useState(value);

  const validateDuration = (minutes: number): string[] => {
    if (minutes === 0) return ['Duration cannot be zero'];
    if (minutes > 24 * 60) return ['Duration cannot be more than 24 hours'];
    return [];
  };

  const handleCronChange = (cron: string) => {
    const newValue = { ...localValue, cron: cron as CRON };
    setLocalValue(newValue);
    onChange?.(newValue);
  };

  const handleDurationChange = (duration: DurationState) => {
    const totalMinutes = (duration.hours || 0) * 60 + (duration.minutes || 0);
    const durationErrors = validateDuration(totalMinutes);
    
    setErrors(prev => ({ ...prev, duration: durationErrors }));
    const newValue = { ...localValue, durationMinutes: totalMinutes };
    setLocalValue(newValue);
    onChange?.(newValue);
  };

  const handleCronValidationErrors = (cronErrors: string[]) => {
    setErrors(prev => ({ ...prev, cron: cronErrors }));
  };

  useEffect(() => {
    onValidationErrors?.([...errors.cron, ...errors.duration]);
  }, [errors]);

  useEffect(() => {
    setLocalValue(value);
    setErrors(prev => ({ ...prev, duration: validateDuration(value.durationMinutes) }));
  }, [value]);

  return (
    <div className="space-y-4">
      {showCron && (
        <div>
          <div className="font-bold text-sm">Schedule</div>
          <div className="italic text-sm mb-1">Define when this trigger should run</div>
          <InputCron
            cron={localValue.cron}
            onValidationErrors={handleCronValidationErrors}
            onChangeCron={handleCronChange}
          />
        </div>
      )}

      {showDuration && (
        <div>
          <div className="font-bold text-sm">Duration (HH:MM)</div>
          <div className="italic text-sm mb-1">How long should this trigger run for</div>
          <InputDuration
            labelText=""
            value={{ 
              hours: Math.floor(localValue.durationMinutes / 60), 
              minutes: localValue.durationMinutes % 60 
            }}
            minutesStep={10}
            onChange={handleDurationChange}
            errors={errors.duration}
          />
        </div>
      )}
    </div>
  );
};
