import { Site } from 'vigil-flow';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';

interface SitesMembersProps {
  availableSites: Site[];
  selectedSites: Site[];
  onChange?: (sites: Site[]) => void;
}

export const SitesMembers: React.FC<SitesMembersProps> = ({
  availableSites,
  selectedSites,
  onChange,
}) => {
  // Convert sites to tag options
  const siteOptions: TagOption[] = availableSites.map(site => ({
    label: site.name,
    value: site.uuid,
  }));

  const selectedTags: TagOption[] = selectedSites.map(site => ({
    label: site.name,
    value: site.uuid,
  }));

  const handleChange = (tags: TagOption[]): void => {
    // Convert selected tags back to Site objects
    const sites = tags.map(tag => {
      const site = availableSites.find(s => s.uuid === tag.value);
      return site!;
    });
    onChange?.(sites);
  };

  return (
    <div>
      <div className="font-bold text-sm">Sites</div>
      <div className="italic text-sm">Select the sites that should be linked to this flow (order matters)</div>
      <InputTagSelect
        options={siteOptions}
        selectedTags={selectedTags}
        onChange={handleChange}
        errors={[]}
        labelText=""
      />
    </div>
  );
};
