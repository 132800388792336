import React, { useContext, useState, useEffect } from 'react';
import useBreakpoints from '../../hooks/use_breakpoints';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { VigilMap, VigilMapController, Coordinate, VigilMapMessageId, VigilMapV2Props, VigilMapV2ThemeId, getBoundingBox } from 'vigil-map';
import { TTuuid } from 'tt-uuid';
import { ContextTheme } from '../../providers/provider_theme';
import { InputButton } from '../../components/input_button';
import { IconDeviceMobile, IconMapPin, IconSquare3Stack3D } from '../../components/icons';
import { useCaller } from '../../hooks/use_caller';
import { IMapSearchResults } from 'vigil-datamodel';

interface ScreenHomeOverviewMapProps { }

export const ScreenHomeOverviewMap: React.FC<ScreenHomeOverviewMapProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const breakpoints = useBreakpoints();
  const theme = useContext(ContextTheme);

  /* State */
  const [stateSnapped, setSnapped] = useState(false);
  const [stateMapController, setMapController] = useState(null as null | VigilMapController);
  const [stateSearch, setSearch] = useState("");
  const [stateSearchFocused, setSearchFocused] = useState(false);
  const [stateSearchResult, setSearchResult] = useState(null as IMapSearchResults | null);
  const [stateSearchResultSelected, setSearchResultSelected] = useState(null as number | null);
  const [stateShowBeacons, setShowBeacons] = useState(true);
  const [stateShowDevices, setShowDevices] = useState(true);
  const [stateSatellite, setSatellite] = useState(false);

  const [stateMapProps, setMapProps] = useState({
    theme: stateSatellite ? VigilMapV2ThemeId.SATELLITE : theme.data == 'dark' ? VigilMapV2ThemeId.DARK_MATTER : VigilMapV2ThemeId.BASIC,
    zoomControl: breakpoints['MD'] ? true : false,
  } as VigilMapV2Props);

  // Live state for getting server representation of map
  const stateMapServer = useCaller({
    callback: async () => {
      if (!organization.data) return null;
      return await vigil.functions.overviewMap({ filter: { uuidsOrganizations: [organization.data.uuid], beacons: stateShowBeacons, devices: stateShowDevices } });
    },
    dependencies: { background: [stateShowBeacons, stateShowDevices], normal: [organization.data] },
    intervalTime: 5000
  });

  // Reactive state for getting map package representation of map
  useEffect(() => {
    // Get first organization, later we could have multiple orgs
    const organizationMapState = stateMapServer.result?.organizations[0];
    const mapProps: VigilMapV2Props = { ...stateMapProps };
    if (organizationMapState) {
      // TODO: Perhaps the backend should just return the data as the frontend wants it ? No conversion needed.
      // Convert beacons from backend -> frontend
      if (organizationMapState.beacons && stateShowBeacons) {
        mapProps.beacons = [];
        for (const beacon of organizationMapState.beacons) {
          mapProps.beacons.push({
            uuid: beacon.uuid,
            lat: beacon.latitude,
            lng: beacon.longitude,
            name: beacon.name
          });
        }
      }

      // Convert devices from backend -> frontend
      if (organizationMapState.devices && stateShowDevices) {
        mapProps.devices = [];
        for (const pair of organizationMapState.devices) {
          // Ensure we have the fields we need
          if (!pair.deviceStateLog.locationLat || !pair.deviceStateLog.locationLng) { continue }

          // Create new representation
          mapProps.devices.push({
            uuid: pair.device.uuid,
            user: pair.user ? { name: `${pair.user.firstName} ${pair.user.lastName}` } : undefined,
            chargeLevel: pair.deviceStateLog.chargeLevel || 0,
            chargeState: pair.deviceStateLog.chargeState == "plugged" ? "Charging" : "Discharging", // TODO: Come unify this, why do we have two sets for the same thing ?
            lastSeen: TTuuid.decodeCuuid(pair.deviceStateLog.uuid).time,
            lat: pair.deviceStateLog.locationLat,
            lng: pair.deviceStateLog.locationLng,
          });
        }
      }
    }

    mapProps.zoomControl = breakpoints['MD'] ? true : false;

    setMapProps(mapProps)
  }, [stateMapServer.result, breakpoints])

  useEffect(() => {
    // Fit bounds
    const points: Coordinate[] = [];
    stateMapProps.beacons?.forEach((beacon) => {
      points.push({ lat: beacon.lat, lng: beacon.lng });
    })
    stateMapProps.devices?.forEach((device) => {
      points.push({ lat: device.lat, lng: device.lng });
    })

    if (stateMapController && !stateSnapped && points.length > 0) {
      setSnapped(true);
      const bounds = getBoundingBox(points);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: bounds,
        options: {
          maxZoom: 15,
          padding: {
            left: 150,
            right: 150,
            top: 150,
            bottom: 150
          }
        }
      })
    }
  }, [stateMapProps, stateMapController])

  // TODO: Need to do the filtering of the map more robust, this is just a quick fix
  function toggleShowBeacons() {
    setShowBeacons(!stateShowBeacons);
    setMapProps({ ...stateMapProps, beacons: stateShowBeacons ? [] : stateMapProps.beacons });
  }

  function toggleShowDevices() {
    setShowDevices(!stateShowDevices);
    setMapProps({ ...stateMapProps, devices: stateShowDevices ? [] : stateMapProps.devices });
  }

  function toggleSatellite() {
    const stateSatelliteNew = !stateSatellite;
    setSatellite(stateSatelliteNew);
    setMapProps({ ...stateMapProps, theme: stateSatelliteNew ? VigilMapV2ThemeId.SATELLITE : theme.data == 'dark' ? VigilMapV2ThemeId.DARK_MATTER : VigilMapV2ThemeId.BASIC })
  }

  function navigateMap(location: { latitude: number, longitude: number, radius: number }) {
    console.log("navigateMap", location);
    const bounds = getBoundingBox([
      { lat: location.latitude + location.radius, lng: location.longitude + location.radius },
      { lat: location.latitude - location.radius, lng: location.longitude - location.radius }
    ]);
    stateMapController?.sendVigilMessage({
      id: VigilMapMessageId.FIT_BOUNDS,
      bounds: bounds,
      options: {
        maxZoom: 15,
        padding: {
          left: 150,
          right: 150,
          top: 150,
          bottom: 150
        }
      }
    })
  }

  useEffect(() => {
    async function search(value: string) {
      setSearch(value);
      if (!value) {
        setSearchResult(null);
        return;
      }

      try {
        // set loading to true
        const result = await vigil.functions.mapSearch({ filter: { search: value } });
        if (stateSearch === value) {
          setSearchResult(result);
        }
      }
      catch (error) {
        // set error
      }
      finally {
        // set loading to false
      }
    }
    search(stateSearch)
  }, [stateSearch])

  return (
    <div className='w-full h-full rounded-xl overflow-hidden'>
      <VigilMap setMapController={setMapController} state={stateMapProps}>
        <div className='w-full h-full flex flex-col'>
          <div className='m-3 pointer-events-auto w-auto md:w-80'>
            <form
              onSubmit={(e) => {
                console.log("Submit");
                e.preventDefault();
                if (stateSearchResult && stateSearchResult.results.length > 0) {
                  navigateMap(stateSearchResult.results[0].location)
                }
              }}
            >
              <label className="input input-bordered flex items-center gap-2">
                <input type="text" className="grow" placeholder="Search"
                  value={stateSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={(e) => setSearchFocused(true)}
                  onBlur={(e) => {
                    // Timeout is required to allow the click event to trigger before we hide the search results
                    setTimeout(() => {
                      setSearchFocused(false)
                    }, 100)
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="h-4 w-4 opacity-70">
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd" />
                </svg>
              </label>
            </form>
            {
              stateSearchFocused && stateSearchResult && <div className='mt-2 card bg-white'>
                <ul className="menu bg-base-100 rounded-box">
                  {
                    stateSearchResult.results.map((result, index) => {
                      return <li key={index}> <a onClick={() => { navigateMap(result.location) }}> {result.title} </a> </li>
                    })
                  }
                </ul>
              </div>
            }
          </div>
          <div className='flex-grow'></div>
          <div>
            <div className='flex p-3 space-x-3'>
              <InputButton className='pointer-events-auto' before={<IconMapPin className='h-5 mr-2' />} text='Beacons' size='btn-sm' type={stateShowBeacons ? 'btn-primary' : 'btn-default'} onClick={toggleShowBeacons}></InputButton>
              <InputButton className='pointer-events-auto' before={<IconDeviceMobile className='h-5 mr-2' />} text='Devices' size='btn-sm' type={stateShowDevices ? 'btn-primary' : 'btn-default'} onClick={toggleShowDevices}></InputButton>
              <InputButton className='pointer-events-auto' before={<IconSquare3Stack3D className='h-5 mr-2' />} text='Satellite' size='btn-sm' type={stateSatellite ? 'btn-primary' : 'btn-default'} onClick={toggleSatellite}></InputButton>
            </div>
          </div>
        </div>
      </VigilMap>
    </div>
  );
};
