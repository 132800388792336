import { FlowConfig } from '../base';

export const FlowDevicePatrol: FlowConfig = (() => {
  const SCREEN_SIGN_IN_REQUIRED = "SCREEN_SIGN_IN_REQUIRED"
  const SOUND_ALERT_1 = "SOUND_ALERT_1"
  const LOG_SIGN_IN_MISSED_DEVICE = "LOG_SIGN_IN_MISSED_DEVICE"
  const LOG_NO_BEACONS = "LOG_NO_BEACONS"
  const LOG_SIGN_IN_MISSED_SERVER = "LOG_SIGN_IN_MISSED_SERVER"
  const LOG_NO_DEVICES_LINKED = "LOG_NO_DEVICES_LINKED"

  return {
    config: {
      id: "device_patrol",
      name: "Device Patrol",
      description: "Flow that runs on a device to patrol a site/sites. This flow will force the user to sign in to the device before it can be used.",
      triggers: [{ type: "schedule" }],
      parameters: [
        {
          id: "beacon_selection_mode",
          name: "Beacon Selection Mode",
          description: "Determines how the patrol will select the next beacon to scan.",
          required: true,
          typing: {
            type: "option",
            options: [
              {
                value: "random",
                label: "Random"
              },
              {
                value: "alphabetical",
                label: "Alphabetical"
              }
            ]
          }
        },
        {
          id: "rest_time_minutes",
          name: "Rest Time",
          description: "Specifies the rest period after completing a patrol sequence, allowing the guard to rest before starting the next cycle.",
          required: true,
          typing: {
            type: "duration",
          }
        },
        {
          id: "time_till_next_beacon_minutes",
          name: "Time Till Next Beacon",
          description: "Specifies the waiting period, before automatically selecting the next beacon in the patrol sequence.",
          required: true,
          typing: {
            type: "duration",
          }
        }
      ],
      members: ['sites', 'devices'],
      deviceScreens: [
        {
          id: SCREEN_SIGN_IN_REQUIRED,
          code: (context) => {
            const c = context.components;
            return (<>
              <c.Modal isOpen={true} onClose={() => { }}>
                <c.Text variant="h3" margin={2} color="black" center={true}>Sign In Required</c.Text>
                <c.Text variant="p" margin={6} color="black" center={true}>User sign in is required in order to start the patrol.</c.Text>
                <c.Button
                  text="OK"
                  variant="btn-primary"
                  margin={4}
                  onPress={async () => {
                    context.device.setAudio(null)
                    context.device.setScreen(null)
                    // TODO: Maybe add user acknowledgement log here
                  }}
                />
              </c.Modal>
            </>)
          }
        }
      ],
      canBeTriggeredByOtherFlows: true
    },
    device: {
      tick: async (context, flowInstance) => {
        const now = new Date();
        const logs = await flowInstance.getLogs();
        const parameters = flowInstance.getParameters();
        const beaconSelectionMode = parameters.beacon_selection_mode;
        const user = await context.device.getUser();

        async function signInAlertLog() {
          const lastLogSignIn = logs.filter((log) => { log.tags.includes(LOG_SIGN_IN_MISSED_DEVICE) }).pop()
          if (!lastLogSignIn || now > new Date(lastLogSignIn.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "Sign in alert" }, [LOG_SIGN_IN_MISSED_DEVICE])
          }
        }

        async function noBeaconsLog(uuidSite: string) {
          const lastLogNoBeacons = logs.filter((log) => { log.tags.includes(LOG_NO_BEACONS) && log.data.uuidSite === uuidSite }).pop()
          if (!lastLogNoBeacons || now > new Date(lastLogNoBeacons.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "No beacons found", uuidSite }, [LOG_NO_BEACONS])
          }
        }

        async function promptUserSignIn() {
          // Get current screen
          const screen = context.device.getScreen();

          // If there is no modal, or a different model, show modal
          if (!screen || screen.id != SCREEN_SIGN_IN_REQUIRED) {
            context.device.setAudio({ id: SOUND_ALERT_1, base64Audio: context.vigil.audioBase64.alert_1, loop: 50 });
            context.device.setScreen({ id: SCREEN_SIGN_IN_REQUIRED });
          }

          await signInAlertLog()
        }

        // If user is not signed in, show sign in modal
        if (!user) { await promptUserSignIn(); return; }

        // If user has not signed in in this session, but has been signed in
        const deviceActivityLogs = await context.device.getActivityLogs(flowInstance.dateTimeStart)
        const signedInActivityLogs = deviceActivityLogs.filter((log) => { log.type === 'userLogin' })
        if (signedInActivityLogs.length === 0 && user) { await context.device.signOutUser(); await promptUserSignIn(); }

        // Start patrol logic
        // TODO
        const siteMembers = await flowInstance.getSiteMembers()

        for (const siteMember of siteMembers) {
          // Get all beacons linked to site
          const beacons = await context.organization.getBeaconsLinkedToSite(siteMember.uuid)
          if (beacons.length === 0) { await noBeaconsLog(siteMember.uuid); return; }

          // Get previously scanned beacons
          // const previouslyScannedBeacons = beacons.filter((beacon) => { beacon.tags.includes(LOG_BEACON_SCANNED) })

          if (beaconSelectionMode === "random") {

          }
          if (beaconSelectionMode === "alphabetical") {

          }
        }
      },
    },
    server: {
      tick: async (context, flowInstance) => {
        const now = new Date();
        const logs = await flowInstance.getLogs();
        const parameters = flowInstance.getParameters();
        const beaconSelectionMode = parameters.beacon_selection_mode;

        async function noDevicesLinkedLog() {
          const lastLogNoDevices = logs.filter((log) => { log.tags.includes(LOG_NO_DEVICES_LINKED) }).pop()
          if (!lastLogNoDevices || now > new Date(lastLogNoDevices.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "No devices linked to this flow. You need to link at least one device to this flow OR add a Adopt Site Specific Flows flow that will link devices dynamically." }, [LOG_NO_DEVICES_LINKED])
          }
        }

        async function userNotSignedInLog(uuidDevice: string) {
          const lastLogSignInMissed = logs.filter((log) => { log.tags.includes(LOG_SIGN_IN_MISSED_SERVER) && log.data.uuidDevice === uuidDevice }).pop()
          if (!lastLogSignInMissed || now > new Date(lastLogSignInMissed.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "User not signed in", uuidDevice }, [LOG_SIGN_IN_MISSED_SERVER])
          }
        }

        async function noBeaconsLog(uuidSite: string) {
          const lastLogNoBeacons = logs.filter((log) => { log.tags.includes(LOG_NO_BEACONS) && log.data.uuidSite === uuidSite }).pop()
          if (!lastLogNoBeacons || now > new Date(lastLogNoBeacons.datetime.valueOf() + 10 * 60 * 1000)) {
            await flowInstance.createLog({ msg: "No beacons found", uuidSite }, [LOG_NO_BEACONS])
          }
        }

        // If 10 minutes has not yet passed, skip
        if (now < new Date(flowInstance.dateTimeStart.valueOf() + 10 * 60 * 1000)) return;

        // Get all device members
        const deviceMembers = await flowInstance.getDeviceMembers()

        // If no devices are linked
        if (deviceMembers.length === 0) { await noDevicesLinkedLog(); return; }

        // If there are devices, for each device
        for (const deviceMember of deviceMembers) {
          // Get device SDK
          const deviceSDK = await context.organization.getSDKDevice(deviceMember.uuid)
          if (!deviceSDK) { console.error("Device SDK not found"); continue; }

          // Get user
          const user = await deviceSDK.getUser()

          // If user is null, create log
          if (!user) { await userNotSignedInLog(deviceMember.uuid); continue; }

          // If user is signed in, check patrolling logic
          // TODO: Implement patrolling logic
          const siteMembers = await flowInstance.getSiteMembers()

          for (const siteMember of siteMembers) {
            const beacons = await context.organization.getBeaconsLinkedToSite(siteMember.uuid)
            if (beacons.length === 0) { await noBeaconsLog(siteMember.uuid); continue; }

            // Get previously scanned beacons
            // const previouslyScannedBeacons = beacons.filter((beacon) => { beacon.tags.includes(LOG_BEACON_SCANNED) })

            if (beaconSelectionMode === "random") {

            }
            if (beaconSelectionMode === "alphabetical") {

            }
          }
        }
      }
    }
  }
})()