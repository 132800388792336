import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { flowInstances } from "./bean_flow_instance";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";

export const linkFlowInstanceMembers = sqliteTable('link_flow_instance_members', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidFlowInstance: text('uuid_flow_instance').notNull().references(() => flowInstances.uuid),
  uuidMember: text('uuid_member').notNull(),
}, (table) => ({
  flowInstanceMemberIdx: uniqueIndex('flow_instance_member_idx').on(table.uuidOrganization, table.uuidFlowInstance, table.uuidMember)
}));

export const linkFlowInstanceMembersRelations = relations(linkFlowInstanceMembers, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkFlowInstanceMembers.uuidOrganization],
    references: [organizations.uuid],
  }),
  flowInstance: one(flowInstances, {
    fields: [linkFlowInstanceMembers.uuidFlowInstance],
    references: [flowInstances.uuid],
  }),
}));