import { IBaseSyncable } from "tt-coms";
import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { organizations } from "./bean_organization";
import { flowInstances } from "./bean_flow_instance";

export type IFlowTriggerSchedule = {
  type: "schedule";
  value: {
    cron: string;
    durationMinutes: number;
  };
}

export type IFlowTrigger = IFlowTriggerSchedule;

export interface IFlow extends IBaseSyncable {
  uuidOrganization: string;
  name: string;
  id: string;
  version: number;
  triggers: IFlowTrigger[];
  triggersLabel: string;
  parameterValues: Record<string, any>;
  parametersLabel: string;
  uuidMembers: string[];
  membersLabel: string;
  config: string;
}

export const flows = sqliteTable('flows', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  id: text('id').notNull(),
  version: integer('version').notNull().default(0),
  triggers: customJsonColumn<IFlowTrigger[]>('triggers').notNull(),
  triggersLabel: text('triggers_label').notNull(),
  parameterValues: customJsonColumn<Record<string, any>>('parameter_values').notNull(),
  parametersLabel: text('parameters_label').notNull(),
  uuidMembers: customJsonColumn<string[]>('uuid_members').notNull(),
  membersLabel: text('members_label').notNull(),
  config: text('config').notNull(),
});

export const flowsRelations = relations(flows, ({ one, many }) => ({
  organization: one(organizations, {
    fields: [flows.uuidOrganization],
    references: [organizations.uuid],
  }),
  flowInstances: many(flowInstances),
}));
