import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { devices } from "./bean_device";
import { relations } from "drizzle-orm";
import { flowInstances } from "./bean_flow_instance";
import { organizations } from "./bean_organization";

// We added this table to keep track of the devices that have terminated a flow instance
// The reason why we are not doing it on the flow instance itself, is because of syncing reasons, there is a scenario where it can happen that two devices update the flow instance at the same time, which could cause issues

export const flowInstanceDeviceTermination = sqliteTable('flow_instance_device_termination', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidFlowInstance: text('uuid_flow_instance').notNull().references(() => flowInstances.uuid),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
}, (table) => ({
  flowInstanceDeviceTerminationIdx: uniqueIndex('flow_instance_device_termination_idx').on(table.uuidOrganization, table.uuidFlowInstance, table.uuidDevice)
}));

export const flowInstanceDeviceTerminationRelations = relations(flowInstanceDeviceTermination, ({ one }) => ({
  organization: one(organizations, {
    fields: [flowInstanceDeviceTermination.uuidOrganization],
    references: [organizations.uuid],
  }),
  flowInstance: one(flowInstances, {
    fields: [flowInstanceDeviceTermination.uuidFlowInstance],
    references: [flowInstances.uuid],
  }),
  device: one(devices, {
    fields: [flowInstanceDeviceTermination.uuidDevice],
    references: [devices.uuid],
  }),
}));